<template>
	<div class="box box_mm">
		<div class="box_main">
			<search @search="search" @reset="reset" ref="mysearch" url="/api/proposa/index" :hiddenbldw="true" :hiddenhb="true">
				
			</search>
			<div class="box_cont">
				<div class="btn_list">
					<!-- <el-button type="primary" @click="goaddnew">新增</el-button> -->
				</div>
				<div style="height: 76vh;box-sizing: border-box;">
					<MyTable ref="zjlist" :tableData="tableData" :tableHeader="tableHeader" :showbm="true" :rowdraggable="true"
						:czwidth="100" @getdata="gettable" @changeinput="changeinput" @rowdrop="rowdrop">
						<template slot="btns" slot-scope="{ scope }">
							<el-button @click="look(scope.row)" type="success" size="mini">详情</el-button>
							<!-- <el-button @click="showyj(scope.row)" v-show="!scope.row.scyjlist" type="primary"
								size="mini" style="margin-left: 10px;">
								预审查意见详情
							</el-button> -->
						</template>
					</MyTable>
				</div>
			</div>
		</div>


		<!--详情-->
		<mydetail v-if="showdetail" :id="detail_id" :showdy="true" requsturl="/api/proposa/details"
			@closedetail="showdetail=false">
		</mydetail>

		<!--弹出-->
		<el-dialog :title="lianform.id?'编辑预审查意见':'预审查意见'" :visible.sync="lianshow" width="40%"
			:before-close="closelian">
			<div>
				<el-form ref="lianform" :model="lianform" :rules="lianrules" label-width="100px">
					<el-form-item label="建议" required>
						<el-radio-group v-model="lianform.type">
							<el-radio label="建议立案">建议立案</el-radio>
							<el-radio label="建议转意见">建议转意见</el-radio>
							<el-radio label="建议不立案">建议不立案</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="预审查意见" prop="scyj">
						<el-input type="textarea" :rows="5" placeholder="请输入" v-model="lianform.scyj">
						</el-input>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="closelian">取 消</el-button>
				<el-button type="primary" @click="qrlian">确 定</el-button>
			</span>
		</el-dialog>


	</div>
</template>

<script>
	import mydetail from "@/components/detail_tc.vue";
	import search from '../component/search';
	import XLSX from 'xlsx-js-style';
	export default {
		components: {
			mydetail,
			search,
		},
		data() {
			return {
				showdetail: false,
				detail_id: '',
				searchinput: '',
				tableHeader: [{
						name: '提案号',
						prop: 'code',
						width: '160',
						type:'input'
					},
					{
						name:'流水号',
						prop:'lscode',
						width:'80'
					},
					{
						name: '标题',
						prop: 'name',
						width: ''
					},
					{
						name: '提案类别',
						prop: 'lei_name',
						width: '150'
					},
					{
						name: '提案人',
						prop: 'taz',
						width: '150'
					},
					{
						name: '状态',
						prop: 'type',
						width: '120',
						type: 'tags'
					},
					{
						name: '提交时间',
						prop: 'addtime',
						width: '150'
					}
				],
				tableData: [],
				rowdata: '',
				lianshow: false,
				czid: '', //当前操作的数据id

				tcname: '',
				lianform: {
					id: '',
					proposa_id: '',
					scyj: '',
					type: '建议立案'
				},
				lianrules: {
					scyj: [{
						required: true,
						message: '请输入',
						trigger: 'change'
					}]
				},

			}
		},
		mounted() {
			this.$refs.mysearch.searyjstype = '2'

			this.gettable()

		},
		activated() {
			this.gettable(); // 每次进入页面时刷新数据
		},
		methods: {
			rowdrop(rowdata){
				this.$post({
					url: '/api/proposa/xgtah',
					params: {
						id:rowdata.oldrow.id,
						code:rowdata.newrow.code
					}
				}).then((res) => {
					this.$message.success('操作成功')
					this.gettable()
				})
			},
			changeinput(row){
				this.$post({
					url: '/api/proposa/xgtah',
					params: {
						id:row.id,
						code:row.code
					}
				}).then((res) => {
					this.$message.success('操作成功')
					this.gettable()
				})
			},
			//查看意见
			showyj(item) {
				this.$refs.myyjlist.tianname = item.proposa_name
				this.$refs.myyjlist.tableData = item.cxylist
				this.$refs.myyjlist.showyy = true
			},

			//导出excel----------------------------------------------------------------------------------------------------------------------
			muludaochu() {
				this.$post({
					url: '/api/proposa/cxyjlist',
					params: {
						p: 1,
						size: 9999,
						name: this.$refs.mysearch.searchinput,
						type: '1'
					}
				}).then((res) => {
					// 创建工作簿
					let wb = XLSX.utils.book_new();

					// 定义工作表数据数组
					let ws_data = [
						['以提案汇总'], // 第一行，后面将合并单元格
						['提案号', '标题', '提案人', '状态', '提交时间', '预审查人', '预审查意见', '理由'] // 第二行，列头
					];
					
					// 第一行标题样式
					const titleStyle = {
						font: {
							name: '宋体',
							sz: 18,
							bold: true
						},
						alignment: {
							horizontal: 'center',
							vertical: 'center'
						},
					};
					
					// 第二行列头样式
					const headerStyle = {
						font: {
							name: '宋体',
							sz: 15,
							bold: true
						},
						alignment: {
							horizontal: 'center',
							vertical: 'center'
						},
						border: {
							top: {
								style: 'thin'
							},
							bottom: {
								style: 'thin'
							},
							left: {
								style: 'thin'
							},
							right: {
								style: 'thin'
							}
						}
					};
					
					// 数据样式
					const dataStyle = {
						font: {
							name: '宋体',
							sz: 14
						},
						alignment: {
							horizontal: 'center',
							vertical: 'center'
						},
						border: {
							top: {
								style: 'thin'
							},
							bottom: {
								style: 'thin'
							},
							left: {
								style: 'thin'
							},
							right: {
								style: 'thin'
							}
						}
					};
					

					// 添加数据行和合并单元格逻辑
					let mergeRanges = []; // 存储合并范围的数组
					
					// 这里是处理数据并填充ws_data逻辑的位置
					res.list.forEach((item, index) => {
					  // 对每个提案，我们至少需要添加一个行数据
					  let baseRow = ws_data.length;
					  let cxyList = item.cxylist || [];
					  let row = [
					    item.code, 
					    item.proposa_name, 
					    item.taz, 
					    '接收待立案', // 通过 item.type 设置固定的 '接收待立案' 状态
					    item.addtime
					  ];
					  ws_data.push(row.concat(cxyList.length > 0 ? [cxyList[0].user_name,cxyList[0].type,cxyList[0].scyj] : ['aaa', 'bbb', 'ccc']));
					
					  // 如果 cxylist 有多于一个元素，将其余元素加入ws_data，并记录合并范围
					  if (cxyList.length > 1) {
					    cxyList.slice(1).forEach(cxy => {
					      ws_data.push([null, null, null, null, null, cxy.user_name, cxy.type, cxy.scyj]);
					    });
					
					    // 合并 '提案号', '标题', '提案人', '状态', '提交时间' 这些列
					    for (let col = 0; col < 5; col++) {
					      mergeRanges.push({
					        s: { r: baseRow, c: col },
					        e: { r: baseRow + cxyList.length - 1, c: col }
					      });
					    }
					  }
					});
					
					// 转换数据数组到工作表
					let ws = XLSX.utils.aoa_to_sheet(ws_data);
					
					// 应用标题样式并合并第一行的单元格
					ws['A1'].s = titleStyle;
					ws['!merges'] = ws['!merges'] || [];
					ws['!merges'].push(XLSX.utils.decode_range('A1:H1'));
					
					// 应用列头样式
					for (let col = 0; col < 8; col++) { // 假设有8列
					  const headerCellRef = XLSX.utils.encode_cell({r: 1, c: col}); // r为1表示第二行
					  ws[headerCellRef].s = headerStyle;
					}
					
					// 应用数据样式到所有单元格，并考虑合并单元格
					for (let R = 2; R < ws_data.length; R++) {
					  for (let C = 0; C < 8; C++) { // 假设数据最多到H列
					    let cellRef = XLSX.utils.encode_cell({r: R, c: C});
					    ws[cellRef] = ws[cellRef] || {}; // 如果单元格不存在，则创建
					    ws[cellRef].s = dataStyle;
					  }
					}
					
					// 添加合并范围到工作表
					mergeRanges.forEach(range => {
					  ws['!merges'].push(range);
					});
					
					// 设置工作表的列宽
					ws['!cols'] = [
					  { wch: 10 }, // 提案号列宽
					  { wch: 50 }, // 标题列宽
					  { wch: 10 }, // 
					  { wch: 18 }, // 
					  { wch: 25 }, // 
					  { wch: 15 }, // 
					  { wch: 15 }, // 
					  { wch: 80 }, // 
					];
					
					// 把工作表添加到工作簿
					XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
					
					// 定义导出的文件名
					let exportFileName = '以提案汇总.xlsx';
					
					// 导出 Excel 文件
					XLSX.writeFile(wb, exportFileName, {
					  bookType: 'xlsx',
					  type: 'binary'
					});
				});

			},
			//导出excel-------------------------------END---------------------------------------------------------------------------------------

			bulian(item) {
				this.$prompt('请输入不予立案的理由', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					inputPattern: /.+/,
					inputErrorMessage: '请输入'
				}).then(({
					value
				}) => {
					this.$post({
						url: '/api/proposa/byla',
						params: {
							id: item.id,
							bylaly: value
						}
					}).then((res) => {
						this.$message.success('操作成功')
						this.gettable()
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '取消操作'
					});
				})
			},



			//===========================立案
			closelian() {
				this.lianform = {
					id: '',
					proposa_id: '',
					scyj: '',
					type: '建议立案'
				}
				this.lianshow = false
			},
			qrlian() {
				this.$refs.lianform.validate((valid) => {
					if (valid) {
						this.$post({
							url: this.lianform.id ? '/api/proposa/xgcxyj' : '/api/proposa/addcxyj',
							params: this.lianform
						}).then((res) => {
							this.$message.success('操作成功')
							this.closelian()
							this.gettable()
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			lian(item, ii) { //显示弹窗
				this.lianform.proposa_id = item.id
				this.lianshow = true
			},
			edit(item) {
				this.isedit = true
				this.lianform = {
					id: item.scyjlist.id,
					proposa_id: item.id,
					scyj: item.scyjlist.scyj,
					type: item.scyjlist.type
				}
				this.lianshow = true
			},



			look(item) {
				this.rowdata = item
				this.detail_id = item.id
				this.showdetail = true
			},
			goaddnew() {
				this.$router.push('/system/add_zhengji')
			},
			addnew() {
				this.$router.push('/system/swiper_add')
			},
			reset() {
				this.$refs.mysearch.searchinput = ''
				this.$refs.mysearch.searchlei = ''
				this.$refs.mysearch.searchhb = ''
				this.$refs.mysearch.searchstatusvalue = ''
				this.$refs.mysearch.searchstatus = ''
				this.$refs.mysearch.searyjstype = '2'
				this.$refs.mysearch.searchbldw = ''
				this.$refs.zjlist.inputpage = 1
				this.gettable()
			},
			search() {
				this.$refs.zjlist.inputpage = 1
				this.gettable()
			},
			gettable() {
				this.$post({
					url: '/api/proposa/index',
					params: {
						p: this.$refs.zjlist.inputpage,
						size: this.$refs.zjlist.pagesize,
						name: this.$refs.mysearch.searchinput
					}
				}).then((res) => {
					res.list.forEach((item, index) => {
						item.type = 1
						item.bltw = item.blusers_name + ';' + item.fbusers_name
					})
					this.tableData = res.list
					this.$refs.zjlist.total = res.count

					//回显打勾
					let selectedlist = []
					res.list.forEach((item, index) => {
						if (item.xuan != 1) {
							selectedlist.push(item)
						}
					})
					this.$refs.zjlist.toggleSelection(selectedlist);
				})
			},
			deleterow(row) {
				this.$confirm('确认删除该条数据吗？')
					.then(_ => {
						this.$post({
							url: '/admin/banner/delete',
							params: {
								id: row.id
							}
						}).then((res) => {
							this.$message.success('删除成功')
							this.gettable()
						})
					})
					.catch(_ => {});

			},
			showsetrole(row) {
				console.log(row)
				this.$refs.mymx.showmx = true
			},
			changswitch(row) {
				console.log(row)
			}

		},
	}
</script>

<style lang="scss" scoped>
	.box_main {
		background-color: #fff;
		padding: 15px 25px;
		width: 100%;
		box-sizing: border-box;
		min-height: 100%;
	}

	.search_box {
		display: flex;
		align-items: center;
		font-size: 0.16rem;

		.search_item {
			display: flex;
			align-items: center;
			margin-right: 0.8rem;

		}
	}

	.box_cont {
		margin-top: 20px;
	}

	.btn_list {
		display: flex;
		align-items: center;
	}

	.tc_title {
		font-size: 0.18rem;
		font-weight: bold;
		color: #333;
		position: relative;
		padding-left: 20px;
		margin-bottom: 20px;
	}

	.tc_title:after {
		content: '';
		position: absolute;
		height: 100%;
		width: 4px;
		left: 0px;
		background-color: rgb(26, 144, 253);
	}

	::v-deep td {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell>.cell {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell.is-leaf {
		background-color: rgb(242, 242, 242);
		color: #777;
	}

	::v-deep .el-table th.el-table__cell.is-leaf,
	.el-table td.el-table__cell {}

	::v-deep .el-table td.el-table__cell {}

	::v-deep .el-table {
		margin-top: 10px;

	}

	::v-deep .el-table__fixed {}

	::v-deep .el-table::before {
		background-color: #fff !important;
	}

	::v-deep .el-table__fixed::before {
		background-color: #fff !important;
	}

	::v-deep .el-dialog__header {
		display: flex !important;
	}

	::v-deep .el-dialog__footer span {
		display: flex !important;
		justify-content: space-around;
		width: 100%;
	}
</style>